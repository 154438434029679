.whiteBox {
  background: #fff;
  border-radius: 6px;
  width: 100%;
  min-height: 100px;
  overflow: hidden;
}
.shadow {
  box-shadow: 0px 0px 20px 3px rgba(76, 76, 76, 0.1);
  transition: all 0.3s ease-in-out;
  top: 0px;
  position: relative;
}
.shadow:hover {
  box-shadow: 0px 0px 28px 4px rgba(76, 76, 76, 0.15);
  top: -10px;
  position: relative;
}
.pad20 {
  padding: 20px;
}

.pad10 {
  padding: 10px;
}
.pad15 {
  padding: 15px;
}
.left {
  float: left;
}

.right {
  float: right;
}

.alignRight {
  text-align: right;
}

.alignLeft {
  text-align: left;
}

.center {
  justify-content: center;
}

.strong {
  font-weight: bold;
}

.space50 {
  height: 50px;
  width: 100%;
  display: block;
}
.space30 {
  height: 30px;
  width: 100%;
  display: block;
}

.space20 {
  height: 20px;
  width: 100%;
  display: block;
}

body #root ~ iframe{
  display: none !important;
}

.ant-page-header-heading-extra {
  display: flex;
  align-items: center;
}
.ant-page-header-heading-extra .ant-form-item {
  margin-bottom: 0 !important;
}


.imgSortingContainer > div {
  display: flex;
  flex-wrap: wrap;
}

.ant-upload-list-picture-card-container > div {
  height: 100%;
}
